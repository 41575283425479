// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";
import { getImage } from "@nested/utils";

import {
  regularHeading,
  sectionHeading,
} from "../../components/Typography/Headings";
import {
  smallParagraph,
  mediumParagraph,
  largeParagraph,
} from "../../components/Typography/Paragraphs";
import { ResponsiveImage } from "../../components/ResponsiveImage";
import { HeroForm } from "../../components/HeroForm";
import { Trustpilot } from "../../icons";

const bigPoppaWrapper = css`
  background-color: ${({ theme }) => theme.palette.hague};
  width: 100%;
  max-width: 1440px;
`;

const wrapper = css`
  width: 100%;
  position: relative;
  max-width: 1180px;
  margin: 0 auto;
`;

const contentWrapper = css`
  ${media.desktop`
    width: 100%;
    display: inline-block;
    vertical-align: top;
  `}
`;

const content = css`
  box-sizing: border-box;
  padding: 30px 20px 0px;
  max-width: 535px;
  margin: 0 auto;
  text-align: center;
  ${media.tablet`
    padding: 30px 20px 10px;
  `}
  ${media.desktop`
    max-width: unset;
    padding: 60px 0 60px 20px;
    width: calc(50% - 20px);
    margin: 0 40px 0 0;
  `}
`;

const mainHeading = css`
  margin: 0;
  margin-bottom: 3.125vw;
  text-align: center;

  ${media.desktop`
    margin: 20px auto 0;
    text-align: left;
  `}
  span {
    display: block;
    ${regularHeading} /* Magical scalable font sizing */ font-size: 8.75vw;
    line-height: 10.75vw;
    margin: 0 calc(6.25vw - 20px);
    color: white;
    ${media.tablet`
      color: white;
      font-size: 42px;
      line-height: 46px;
    `}
    ${media.desktop`
      margin: 0 auto;
      font-size: 4.6vw;
      line-height: 5.3vw;
      color: white;
      display: block;
    `}
        ${media.maxDesktop`
      font-size: 55px;
      line-height: 62px;
    `}
  }
`;

const subTitleStyles = css`
  text-align: center;
  margin: 10px 50px 0;

  span {
    ${mediumParagraph}
    text-align: left;
    color: white;
    opacity: 0.8;
  }

  ${media.tablet`
    white-space: pre-wrap;
    max-width: 460px;
  `}
  ${media.desktop`
    text-align: left;
    margin: 30px 0 0;
    span {
      ${largeParagraph}
      color: white;
    }
  `}
`;

const leftQuote = css`
  &::before {
    content: "\u201C";
    position: absolute;
    top: 0px;
    left: -7px;
    color: ${({ theme }) => theme.palette.pink100};
    ${media.desktop`
      color: white;
    `}
  }
`;

const quoteText = css`
  ${smallParagraph}
  color: white;
  margin: 0;
  font-weight: ${({ bold }) => (bold ? "500" : "400")};
  bottom: 0;
  margin-top: 10px;
  position: relative;
  ${media.desktop`
    text-align: left;
    display: block;
    color: white;
    font-weight: ${({ bold }) => (bold ? "500" : "400")};
  `}
  ${({ leftquote }) => leftquote && leftQuote}
`;

const commaOnMobile = css`
  &:before {
    content: ", ";
  }

  ${media.desktop`
    &:before {
      content: ""
    }
  `}
`;

const imageWrapper = css`
  display: none;
  ${media.desktop`
    display: block;
    width: calc(50% - 40px);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    height: 100%;
    max-height: 100vh;
    margin: 0 20px;
    display: flex;
    align-items: center;
  `}
`;

const heroImage = css`
  background-image: url(${({ image }) => image});
  background-position: center center;
  background-size: ${({ scaleImage }) => (scaleImage ? "cover" : "contain")};
  background-repeat: no-repeat;
  padding-top: calc(100% * 1226 / 1100);
  margin-top: 30px;
  position: relative;
  overflow: hidden;
  border-radius: 30px;
  ${media.tablet`
    margin-top: 40px;
  `}
  ${media.desktop`
    margin-top: 0;
    width: 100%;
  `}
`;

const imageOverlay = css`
  padding: 20px;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 35%,
    rgba(0, 0, 0, 0) 35% 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${media.tablet`
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    p:first-of-type {
      max-width: 225px;
    }
  `}
`;

const mobileTestimonial = css`
  padding: 15px 20px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.hague130};
  ${media.desktop`
    display: none;
  `}
`;

const mobileTestimonialInner = css`
  display: flex;
  align-items: center;
  ${media.tablet`
    max-width: 540px;
    margin: 0 auto;
  `}
`;

const roundCustomerImage = css`
  height: 65px;
  width: 65px;
  border-radius: 50%;
  background-color: pink;
  margin-right: 20px;
  flex-shrink: 0;
`;

const trustpilotWrapper = css`
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  background-color: ${({ theme }) => theme.palette.hague};
  ${media.desktop`
    background-color: ${({ theme }) => theme.palette.hague130};
    width: 40%;
    padding: 30px;
    text-align: left;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      height: 100%;
      width: 100%;
      background-color: ${({ theme }) => theme.palette.hague130};
    }
  `}
`;

const trustpilotText = css`
  display: none;
  ${smallParagraph}
  color: white;
  text-align: center;
  margin: 15px 0 0;
  ${media.desktop`
    display: ${({ wrap }) => (wrap ? "inline-block" : "block")};
    text-align: left;
    margin: 15px 0 0;
  `}
`;

const rightmoveText = css`
  ${trustpilotText}
  display: ${({ wrap }) => (wrap ? "inline-block" : "block")};
  margin-top: 10px;
  ${media.desktop`
    margin-top: 5px;
  `}
`;

const trustpilotStars = css`
  height: 23px;
  margin-right: 15px;
  ${media.desktop`
    display: block;
    margin: 0;
  `}
`;

const trustpilotLink = css`
  text-decoration: none;
`;

const trustpilotLogo = css`
  display: inline-block;
  height: 26px;
  width: 103px;
  ${media.desktop`
    margin: 0 0 0 9px;
    vertical-align: text-bottom;
  `}
`;

type Props = {
  formType?: "onboarding" | "emailSubscription" | "onlineValuation",
  scaleImage?: boolean,
  heading: PrismicRichText,
  subHeading?: string,
  multilineSubTitle: PrismicRichText,
  submitButtonText: string,
  stateOverride?: any,
  testimonial?: boolean,
  testimonialText: string,
  testimonialCustomer: string,
  testimonialCustomerLocation: string,
  testimonialCustomerPhoto: PrismicImage,
  testimonialCustomerRoundHeadshot: PrismicImage,
};

const headingLabel = css`
  ${sectionHeading}
  color: white;
  text-align: center;
  opacity: 0.8;
  margin: 0;
  ${media.desktop`
    margin-top: 30px;
    text-align: left;
  `}
`;

export const HomepageHero = ({
  formType = "onboarding",
  scaleImage = true,
  heading,
  subHeading,
  multilineSubTitle,
  submitButtonText,
  stateOverride,
  testimonial = true,
  testimonialText,
  testimonialCustomer,
  testimonialCustomerLocation,
  testimonialCustomerPhoto,
  testimonialCustomerRoundHeadshot,
}: Props) => {
  return (
    <div css={bigPoppaWrapper}>
      <div css={wrapper}>
        <div css={contentWrapper}>
          <div css={content}>
            {subHeading && <h2 css={headingLabel}>{subHeading}</h2>}
            <h1 css={mainHeading}>
              {heading.map(({ text }, index) => (
                <span key={index}>{text} </span>
              ))}
            </h1>
            {multilineSubTitle.length > 0 && (
              <h3 css={subTitleStyles}>
                {multilineSubTitle.map(({ text }, index) => (
                  <span key={index}>{text} </span>
                ))}
              </h3>
            )}
            <HeroForm
              formType={formType}
              stateOverride={stateOverride}
              submitButtonText={submitButtonText}
            />
            <div css={imageWrapper}>
              <div
                css={heroImage}
                image={testimonialCustomerPhoto.url}
                scaleImage={scaleImage}
              >
                {testimonial && (
                  <div css={imageOverlay}>
                    <p css={quoteText} bold leftquote>
                      {testimonialText}
                    </p>
                    <p css={quoteText}>
                      <span bold css={quoteText}>
                        {testimonialCustomer}
                      </span>
                      <span css={commaOnMobile}>
                        {testimonialCustomerLocation}
                      </span>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div css={trustpilotWrapper}>
            <a
              href="https://uk.trustpilot.com/review/www.nested.com"
              target="_blank"
              css={trustpilotLink}
            >
              <ResponsiveImage
                css={trustpilotStars}
                alt="Five stars"
                src={getImage("icons/Trustpilot_ratings_5star-RGB.png")}
              />
            </a>
            <p css={trustpilotText} wrap>
              Rated excellent on{" "}
            </p>
            <a
              href="https://uk.trustpilot.com/review/www.nested.com"
              target="_blank"
              css={trustpilotLink}
            >
              <Trustpilot fill="white" css={trustpilotLogo} />
            </a>
            {formType !== "onlineValuation" && (
              <p css={rightmoveText}>
                We list on{" "}
                <span>
                  <ResponsiveImage
                    src={getImage("icons/zoopla.svg")}
                    alt="Zoopla"
                    css="width: 53px; height: 14px; vertical-align: text-bottom;"
                  />
                </span>
                ,{" "}
                <span>
                  <ResponsiveImage
                    src={getImage("icons/rightmove.svg")}
                    alt="rightmove"
                    css="width: 95px; height: 19px; vertical-align: sub;"
                  />
                </span>{" "}
                and{" "}
                <span>
                  <ResponsiveImage
                    src={getImage("icons/onthemarket_logo.svg")}
                    alt="OnTheMarket"
                    css="width: 75px; height: 19px; vertical-align: sub; "
                  />
                </span>
              </p>
            )}
          </div>
          {testimonial && (
            <div css={mobileTestimonial}>
              <div css={mobileTestimonialInner}>
                {testimonialCustomerRoundHeadshot?.url && (
                  <img
                    css={roundCustomerImage}
                    src={testimonialCustomerRoundHeadshot.url}
                    alt={testimonialCustomerRoundHeadshot?.alt || ""}
                  />
                )}
                <div>
                  <p
                    css={css`
                      ${quoteText} margin-top: 0;
                    `}
                    leftquote
                  >
                    {testimonialText}
                  </p>
                  <p css={quoteText}>
                    <span bold css={quoteText}>
                      {testimonialCustomer}
                    </span>
                    {testimonialCustomerLocation && (
                      <span css={commaOnMobile}>
                        {testimonialCustomerLocation}
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
